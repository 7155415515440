'use client'

import * as koala from '@/lib/koala'
import { useEffect } from 'react'

export function Analytics() {
  useEffect(() => {
    koala.init()
  }, [])
  return <></>
}
